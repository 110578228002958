import styled from "styled-components";
import Header from "./components/Header";
import RegistrationForm from "./components/form/RegistrationForm";
import ScrollToTopButton from "./components/ScrollToTopButton";
import Products from "./components/products/Products";
import "./App.css";
import A1Img from "./assets/a-1.png";
import A2Img from "./assets/a-2.png";
import A3Img from "./assets/a-3.png";
import A4Img from "./assets/a-4.png";
import InstagramImg from "./assets/instagram.png";
import FacebookImg from "./assets/facebook.png";
import LinkedInImg from "./assets/linkedin.png";
import Section1 from "./components/Section1";
import PakietImg from "./assets/pakiet.png";
import B1Img from "./assets/b-1.png";
import B2Img from "./assets/b-2.png";
import B3Img from "./assets/b-3.png";

const PageContainer = styled.div`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  font-family: "Saira", sans-serif;
  color: #4a4a49;
  background-color: #fff;

  @media screen and (min-width: 768px) {
    max-width: 768px;
  }

  @media screen and (min-width: 1024px) {
    max-width: 1024px;
  }

  @media screen and (min-width: 1440px) {
    max-width: 1440px;
  }
`;

const Section = styled.div`
  padding: 50px 0;
`;

const HeaderSection = styled(Section)`
  padding-top: 0px;
  padding-bottom: 0px;
`;

const About = styled(Section)`
  background-color: #e7eef1;
  text-align: center;
  padding: 50px 20px;
`;

const Rules = styled(Section)`
  text-align: center;
  padding: 40px 20px;
  font-weight: bold;
`;

const Footer = styled(Section)`
  background-color: #0f5090;
  color: #c4c4c4;
  text-align: center;
  font-size: 12px;
  padding: 40px 50px;
`;

const Headline = styled.h1`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: #219dd9;
  margin: 40px auto;
`;

export const Container = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

export const LeftSection = styled.div`
  width: 100%;
  text-align: left;

  @media screen and (min-width: 768px) {
    width: 50%;
    font-size: 80%;
  }

  @media screen and (min-width: 1024px) {
    width: 50%;
    font-size: 100%;
  }
`;

export const RightSection = styled.div`
  width: 100%;

  @media screen and (min-width: 768px) {
    width: 50%;
  }

  @media screen and (min-width: 1024px) {
    width: 50%;
    font-size: 100%;
  }
`;

const StyledImg = styled.img`
  width: 100%;

  @media screen and (min-width: 768px) {
    width: 60%;
  }
`;

export const Link = styled.a`
  background-color: #219dd9;
  color: white;
  padding: 4px 40px;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
  font-family: "Saira", sans-serif;

  @media screen and (min-width: 768px) {
    padding: 6px 10px;
    font-size: 14px;
    line-height: 28px;
  }

  @media screen and (min-width: 1024px) {
    padding: 6px 15px;
    font-size: 16px;
    line-height: 38px;
  }

  @media screen and (min-width: 1440px) {
    padding: 6px 40px;
    font-size: 18px;
    line-height: 48px;
  }
`;

function App() {
  return (
    <PageContainer>
      <HeaderSection>
        <Header />
      </HeaderSection>
      <Section1 />
      <About>
        <Headline>
          DLACZEGO STOSUJEMY DEZYNFEKCJĘ W GABINECIE STOMATOLOGICZNYM:
        </Headline>
        <div className="grid-container">
          <div className="grid-card">
            <img src={A1Img} alt="" style={{ marginBottom: "20px" }} />
            <p>
              Naruszenie ciągłości tkanek i kontakt z krwią podczas zabiegów
              stomatologicznych
            </p>
          </div>
          <div className="grid-card">
            <img src={A2Img} alt="" style={{ marginBottom: "20px" }} />
            <p>
              Kontakt zakażonej krwi z uszkodzoną skórą, błoną śluzową zdrowej
              osoby
            </p>
          </div>
          <div className="grid-card">
            <img src={A3Img} alt="" style={{ marginBottom: "20px" }} />
            <p>Ryzyko wniknięcia do organizmu czynników chorobotwórczych</p>
          </div>
          <div className="grid-card">
            <img src={A4Img} alt="" style={{ marginBottom: "20px" }} />
            <p>Zagrożenie powstania poważnych powikłań i chorób</p>
          </div>
        </div>
      </About>
      <Products />
      <About style={{ paddingBottom: "20px" }}>
        <Headline>POZNAJ ZAWARTOŚĆ PAKIETU PROMOCYJNEGO</Headline>
        <Container>
          <LeftSection>
            <p style={{ marginBottom: "40px" }}>
              W skład Pakietu wchodzą produkty niezbędne w gabinecie
              stomatologicznym w celu zapewnienia prawdziwej dezynfekcji podczas
              codziennej pracy - must have stomatologa!
            </p>
            <ul style={{ marginBottom: "40px", listStyleType: "disc" }}>
              <strong>W skład Pakietu wchodzą:</strong>
              <li style={{ marginLeft: "20px" }}>Viruton Bohr 1L</li>
              <li style={{ marginLeft: "20px" }}>
                Velox Duo Wipes (puszka z wkładem)
              </li>
              <li style={{ marginLeft: "20px" }}>
                Velodes Silk 500ml z pompką
              </li>
              <li style={{ marginLeft: "20px" }}>Velox Spray 1L (Neutral)</li>
            </ul>
            <ul style={{ marginBottom: "40px", listStyleType: "disc" }}>
              <strong>
                Do każdego Pakietu dołączamy materiały wspierające:
              </strong>
              <li style={{ marginLeft: "20px" }}>
                Ulotka „Bezpieczna organizacja pracy w gabinecie
                stomatologicznym”
              </li>
              <li style={{ marginLeft: "20px" }}>
                Plan higieny w gabinecie stomatologicznym
              </li>
              <li style={{ marginLeft: "20px" }}>
                Certyfikat „Bezpieczny gabinet stomatologiczny”
              </li>
            </ul>
          </LeftSection>
          <RightSection>
            <StyledImg src={PakietImg} alt="" />
          </RightSection>
        </Container>
      </About>
      <Rules>
        <Headline>ZASADY AKCJI PROMOCYJNEJ</Headline>
        <div className="grid-3-cols-container">
          <div className="grid-card">
            <img src={B1Img} alt="" style={{ marginBottom: "20px" }} />
            <p>Kup promocyjny Pakiet produktów MEDISEPT</p>
          </div>
          <div className="grid-card">
            <img src={B2Img} alt="" style={{ marginBottom: "20px" }} />
            <p>Zarejestruj kod</p>
          </div>
          <div className="grid-card">
            <img src={B3Img} alt="" style={{ marginBottom: "20px" }} />
            <p>Odbierz Nagrodę!</p>
          </div>
        </div>
        <p style={{ margin: '15px 0' }}>DOWIEDZ SIĘ GDZIE MOŻESZ ZAMÓWIĆ PAKIET</p>
        <Link href="tel:+48815352222">Skontaktuj się z nami</Link>
      </Rules>
      <RegistrationForm />
      <Footer>
        <div className="grid-container" style={{ marginBottom: "40px" }}>
          <div
            className="grid-card"
            style={{
              textAlign: "left",
              color: "#fff",
              fontSize: "16px",
              lineHeight: "24px",
              padding: 0,
            }}
          >
            <p>
              <strong>Kontakt</strong>
              <br />
              Medisept Sp. z o.o.
              <br />
              ul. Ludwika Spiessa 4<br />
              20-270 Lublin
            </p>
          </div>
          <div
            className="grid-card"
            style={{
              textAlign: "left",
              color: "#fff",
              fontSize: "16px",
              lineHeight: "24px",
              padding: 0,
            }}
          >
            <p>
              <br />
              e-mail: info@medisept.pl
              <br />
              tel. +48 81 535 22 22
            </p>
          </div>
          <div
            className="grid-card"
            style={{
              textAlign: "left",
              color: "#fff",
              fontSize: "16px",
              lineHeight: "24px",
              padding: 0,
            }}
          >
            <p>
              <br />
              NIP: 9460010016
              <br />
              REGON: 430566102
              <br />
              BDO: 000001634
            </p>
          </div>
          <div
            className="grid-card"
            style={{
              textAlign: "left",
              color: "#fff",
              fontSize: "16px",
              lineHeight: "24px",
              padding: 0,
            }}
          >
            <p>
              <strong>Odwiedź nas na:</strong>
              <br />
              <a
                href="https://www.instagram.com/mediseptpl/"
                target="_blank"
                rel="noreferrer"
                style={{ marginRight: "10px" }}
              >
                <img src={InstagramImg} alt="Instagram" />
              </a>
              <a
                href="https://www.facebook.com/mediseptpl/?locale=pl_PL"
                target="_blank"
                rel="noreferrer"
                style={{ marginRight: "10px" }}
              >
                <img src={FacebookImg} alt="Facebook" />
              </a>
              <a
                href="https://www.linkedin.com/company/medi-sept/?originalSubdomain=pl"
                target="_blank"
                rel="noreferrer"
                style={{ marginRight: "10px" }}
              >
                <img src={LinkedInImg} alt="LinkedIn" />
              </a>
            </p>
          </div>
        </div>
        <p>MEDISEPT 2023. All rights reserved</p>
      </Footer>
      <ScrollToTopButton />
    </PageContainer>
  );
}

export default App;
