import React from "react";
import styled from "styled-components";

interface AgreementModalProps {
  description: string;
  onClose: () => void;
}

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const ModalContent = styled.div`
  max-width: 600px;
  height: auto;
  max-height: calc(100% - 60px);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 13px;
  overflow: auto;
  margin: 30px;
`;

const CloseButton = styled.button`
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #219dd9;
  font-family: Saira;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #219dd9;
  background-color: #fff;
  margin: 20px auto 0 auto;
  display: block;
`;

const AgreementModal: React.FC<AgreementModalProps> = ({
  description,
  onClose,
}) => {
  return (
    <ModalOverlay>
      <ModalContent>
        {description}
        <CloseButton onClick={onClose}>Zamknij</CloseButton>
      </ModalContent>
    </ModalOverlay>
  );
};

export default AgreementModal;
