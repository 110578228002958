import React from "react";
import styled from "styled-components";
import { Link } from "../App";

const Section1Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  position: relative;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const Column = styled.div`
  text-align: center;
  width: 100%;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 20px;
  margin-top: 40px;
  font-size: 100%;

  @media screen and (min-width: 768px) {
    margin-bottom: 20px;
    font-size: 80%;
  }

  @media screen and (min-width: 1024px) {
    margin-bottom: 20px;
    font-size: 100%;
  }
`;

const StyledH1 = styled.h1`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: #219dd9;
  margin-bottom: 40px;

  @media screen and (min-width: 768px) {
  }

  @media screen and (min-width: 1024px) {
  }

  @media screen and (min-width: 1440px) {
  }
`;

const Paragraph = styled.p`
  margin-bottom: 40px;
`;

const Section1: React.FC = () => {
  return (
    <Section1Container>
      <Column>
        <StyledH1>
          BEZPIECZEŃSTWO GABINETU MA DLA NAS NAJWYŻSZE ZNACZENIE:
        </StyledH1>
        <Paragraph>
          Produkty MEDISEPT są przebadane wg najnowszych norm w warunkach wysokiego obciążenia organicznego, co odzwierciedla użycie produktu w kontakcie z tkankami, krwią, wydzielinami, wydalinami oraz płynami ustrojowymi. Dzięki temu masz gwarancję PRAWDZIWEJ DEZYNFEKCJI!
         </Paragraph>
         <Paragraph> 
          Bezpieczny proces pracy w gabinecie, to przede wszystkim: dezynfekcja, mycie, a następnie sterylizacja sprzętu wielokrotnego użycia wykorzystywanego podczas zabiegów, mającego kontakt z tkankami miękkimi, tkanką kostną, jamami ciała, błonami śluzowymi oraz uszkodzoną skórą i ranami. Mycie, a następnie dezynfekcja: dużych i małych powierzchni w gabinecie, nieinwazyjnego sprzętu medycznego, narzędzi obrotowych, RĄK!
        </Paragraph>
        <Paragraph>  
          <strong>DOWIEDZ SIĘ WIĘCEJ O BEZPIECZNEJ ORGANIZACJI PRACY W GABINECIE STOMATOLOGICZNYM</strong>
        </Paragraph>
        <Link href="https://medisept.pl/maski/stomatolog/" target="_blank">Czytaj więcej</Link>
      </Column>
    </Section1Container>
  );
};

export default Section1;
