import React from "react";
import ProductCard from "./ProductCard";
import "./Products.css";

import Product1Img from "../../assets/products/viruton_bohr_1L_mini.png";
import Product2Img from "../../assets/products/viruton_extra_1L_mini.png";
import Product3Img from "../../assets/products/chusteczki.png";
import Product4Img from "../../assets/products/velox_duo_wipes_puszka.png";
import Product5Img from "../../assets/products/medisept_dry_wipes_box.png";
import Product6Img from "../../assets/products/velodes_scrub_500ml_mini.png";
import Product7Img from "../../assets/products/velodes_silk_500ml_dozownik.png";
import Product8Img from "../../assets/products/velox_foam_extra_1L_mini.png";
import Product9Img from "../../assets/products/1L_velox_spray_neutral_PL_1.png";
import Product10Img from "../../assets/products/MC_611_500ml_web.png";
import Product11Img from "../../assets/products/velodes_cream_aloe_vera_v2mat_mini.png";
import Product12Img from "../../assets/products/quatrodes_extra_1L.png";
import Product13Img from "../../assets/products/quatrodes_unit_nf_1L_mini.png";
import Product14Img from "../../assets/products/MC_210_ZH_1L.png";
import Product15Img from "../../assets/products/MC_211_500ml.png";
import styled from "styled-components";

const Headline = styled.h1`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: #219dd9;
  margin: 40px auto;
`;

const Products = () => {
  const products = [
    {
      id: 1,
      img: Product1Img,
      title: "Viruton® Bohr",
      description: "Mycie i dezynfekcja narzędzi obrotowych",
      url: "https://medisept.pl/product/viruton-bohr/",
    },
    {
      id: 2,
      img: Product2Img,
      title: "Viruton® Extra",
      description: "Mycie i dezynfekcja narzędzi",
      url: "https://medisept.pl/product/viruton-extra/",
    },
    {
      id: 3,
      img: Product3Img,
      title: "MEDISEPT Chusteczki do dezynfekcji rąk i powierzchni",
      description:
        "Dezynfekcja rąk i wszelkich małych powierzchni odpornych na działanie alkoholu",
      isDoubleColumn: true,
      url: "https://medisept.pl/product/medisept-chusteczki-do-dezynfekcji-rak-i-powierzchni/",
    },
    {
      id: 4,
      img: Product4Img,
      title: "Velox® Duo Wipes",
      description:
        "Mycie i dezynfekcja małych powierzchni i sprzętu medycznego",
      url: "https://medisept.pl/product/velox-duo-wipes/",
    },
    {
      id: 5,
      img: Product5Img,
      title: "MEDISEPT Dry Wipes",
      description:
        "Box Ściereczki do nasączenia preparatem dezynfekującym/myjącym, do mycia i dezynfekcji różnorodnych powierzchni nieinwazyjnych wyrobów medycznych",
      url: "https://medisept.pl/product/medisept-dry-wipes/",
    },
    {
      id: 6,
      img: Product6Img,
      title: "Velodes® Scrub",
      description: "Mycie rąk",
      url: "https://medisept.pl/product/mediclean420/",
    },
    {
      id: 7,
      img: Product7Img,
      title: "Velodes® Silk",
      description: "Higieniczna i chirurgiczna dezynfekcja rąk",
      url: "https://medisept.pl/product/velodes-silk/",
    },
    {
      id: 8,
      img: Product8Img,
      title: "Velox® Foam Extra",
      description: "Mycie i dezynfekcja powierzchni wrażliwych na alkohol",
      url: "https://medisept.pl/product/velox-foam-extra/",
    },
    {
      id: 9,
      img: Product9Img,
      title: "Velox® Spray",
      description: "Mycie i dezynfekcja małych powierzchni",
      url: "https://medisept.pl/product/velox-spray-neutral/",
    },
    {
      id: 10,
      img: Product10Img,
      title: "MEDICLEAN 611 Fresh",
      description: "Maskowanie i usuwanie nieprzyjemnych zapachów",
      url: "https://medisept.pl/product/mediclean611/",
    },
    {
      id: 11,
      img: Product11Img,
      title: "Velodes® Cream Aloe Vera",
      description: "Pielęgnacja rąk",
      url: "https://medisept.pl/product/velodes-cream-aloe-vera/",
    },
    {
      id: 12,
      img: Product12Img,
      title: "Quatrodes® Extra",
      description: "Mycie i dezynfekcja dużych powierzchni",
      url: "https://medisept.pl/product/quatrodes-extra/",
    },
    {
      id: 13,
      img: Product13Img,
      title: "Quatrodes® Unit NF",
      description: "Mycie i dezynfekcja urządzeń ssących",
      url: "https://medisept.pl/product/quatrodes-unit-nf/",
    },
    {
      id: 14,
      img: Product14Img,
      title: "MEDICLEAN 210 Surface",
      description: "Meble i powierzchnie ponadpodłogowe",
      url: "https://medisept.pl/product/mediclean210-zielona-herbata/",
    },
    {
      id: 15,
      img: Product15Img,
      title: "MEDICLEAN 211 Office",
      description: "Meble i sprzęt biurowy",
      url: "https://medisept.pl/product/mediclean211/",
    },
  ];

  return (
    <>
      <Headline>ZADBAJ O BEZPIECZEŃSTWO SWOJE I PACJENTÓW Z PRODUKTAMI MEDISEPT</Headline>
      <div className="products-container">
        {products.map((product, index) => (
          <ProductCard key={index} product={product} />
        ))}
      </div>
    </>
  );
};

export default Products;
