import React from "react";
import styled from "styled-components";
import logo1x from "../../assets/logo.png";
import logo2x from "../../assets/logo@2x.png";

interface ModalProps {
  header: string;
  description: string;
  closeLabel: string;
  onClose: () => void;
}

const Logo = styled.img`
  width: 150px;
  height: auto;
  margin: 20px 0;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const ModalContent = styled.div`
  background-color: white;
  max-width: 540px;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const CloseButton = styled.button`
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #219dd9;
  font-family: Saira;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #219dd9;
  background-color: #fff;
  margin: 20px auto 0 auto;
  display: block;
`;

const Modal: React.FC<ModalProps> = ({ header, description, closeLabel, onClose }) => {
  return (
    <ModalOverlay>
      <ModalContent>
        <Logo
          src={logo1x}
          srcSet={`${logo1x} 1x, ${logo2x} 2x`}
          alt="MediSept"
        />
        <br />
        <h2 style={{ color: '#219DD9' }}>{header}</h2><br />
        <p>
          {description}
        </p>
        <CloseButton onClick={onClose}>{closeLabel}</CloseButton>
      </ModalContent>
    </ModalOverlay>
  );
};

export default Modal;
