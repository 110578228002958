import React, { useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import styled from "styled-components";
import SupplierList from "./SupplierList";
import InputField from "./InputField";
import AgreementCheckboxes, { Agreement } from "./AgreementCheckboxes";
import "./FormAnimation.css";
import ErrorModal from "../modals/ErrorModal";
import Modal from "../modals/Modal";
import { Container, LeftSection, RightSection } from "../../App";
import NagrodaImg from "../../assets/nagroda.png";

declare global {
  interface Window {
    grecaptcha: ReCaptchaInstance;
  }
}

interface ReCaptchaInstance {
  ready: (callback: () => void) => void;
  execute: (
    siteKey: string,
    options: ReCaptchaExecuteOptions
  ) => Promise<string>;
}

interface ReCaptchaExecuteOptions {
  action: string;
}

interface FormContainerProps {
  isVisible: boolean;
}

const FormContainer = styled.div<FormContainerProps>`
  display: ${(props) => (props.isVisible ? "block" : "none")};
  max-width: 960px;
  margin: 0 auto;
  padding: 40px 40px 10px;

  /* Add responsive styles for smaller screens */
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const FormColumns = styled.div`
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  // gap: 20px;

  // /* Add responsive styles for smaller screens */
  // @media (max-width: 768px) {
  //   grid-template-columns: 1fr;
  //   gap: 0px;
  // }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

const SubmitButton = styled.button`
  padding: 5px 20px;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #fff;
  font-family: Saira;
  font-size: 20px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  background-color: #219dd9;
  margin: 0 10px;
  font-weight: bold;
`;

const Headline = styled.h1`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: #219dd9;
  margin-bottom: 40px;
`;

const Infobox = styled.p`
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 90%;
  text-align: center;
`;

const RegistrationForm: React.FC = () => {
  const API_URL = process.env.REACT_APP_API_URL;

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [supplierName, setSupplierName] = useState("");

  const [isNameValid, setIsNameValid] = useState(true);
  const [isCompanyNameValid, setIsCompanyNameValid] = useState(true);
  const [isPostalCodeValid, setIsPostalCodeValid] = useState(true);
  const [isCityValid, setIsCityValid] = useState(true);
  const [isAddressValid, setIsAddressValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isInvoiceNumberValid, setIsInvoiceNumberValid] = useState(true);
  const [isSelectedSupplierValid, setIsSelectedSupplierValid] = useState(true);
  const [isSupplierNameValid, setIsSupplierNameValid] = useState(true);

  const [errors, setErrors] = useState<string[]>([]);

  const [modalHeader, setModalHeader] = useState<string>("");
  const [modalDescription, setModalDescription] = useState<string>("");
  const [modalCloseLabel, setModalCloseLabel] = useState<string>("");

  const otherSupplier = "Inne (wpisz jakie)";

  const suppliers = [
    "APS",
    "LDENT",
    "DENTECH",
    "SB DENTAL",
    "PDD",
    "MODENTIS",
    "ANEKS",
    "DENTART",
    "JRJ",
    otherSupplier,
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const [agreements, setAgreements] = useState<Agreement[]>([]);

  useEffect(() => {
    const getAgreements = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/agreement/list`);
        setAgreements(
          response.data.agreements.map((agreement: Agreement) => ({
            ...agreement,
            checked: false,
          }))
        );
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    getAgreements();
  }, [API_URL]);

  const [recaptchaToken, setRecaptchaToken] = useState<string | null>("");

  const loadReCaptcha = async () => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(
          process.env.REACT_APP_RECAPTCHA_SITE_KEY
            ? process.env.REACT_APP_RECAPTCHA_SITE_KEY
            : "",
          { action: "sendApplication" }
        )
        .then((newToken: string) => {
          setRecaptchaToken(newToken);
        });
    });
  };

  useEffect(() => {
    loadReCaptcha();
  }, []);

  const handlePostalCodeChange = (newValue: string) => {
    // Remove hyphens and non-digit characters
    const sanitizedValue = newValue.replace(/[^0-9]/g, "");

    // Format the value as a postal code mask (e.g., "12-345")
    let formattedValue = sanitizedValue.slice(0, 2);
    if (sanitizedValue.length > 2) {
      formattedValue += "-" + sanitizedValue.slice(2, 5);
    }

    setPostalCode(formattedValue);
  };

  const handleAgreementChange = (agreement: Agreement, isChecked: boolean) => {
    setAgreements((prevState) =>
      prevState.map((a) =>
        a.code === agreement.code
          ? {
              ...a,
              checked: isChecked,
            }
          : a
      )
    );
  };

  const handleSupplierSelect = (supplier: string) => {
    setSelectedSupplier(supplier);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSaving(true);

    // Validate form fields
    let isFormValid = true;
    setIsNameValid(true);
    setIsCompanyNameValid(true);
    setIsPostalCodeValid(true);
    setIsCityValid(true);
    setIsAddressValid(true);
    setIsEmailValid(true);
    setIsPhoneValid(true);
    setIsInvoiceNumberValid(true);
    setIsSelectedSupplierValid(true);
    setIsSupplierNameValid(true);

    setErrors([]);

    const formErrors: string[] = [];

    if (name.trim().length === 0) {
      setIsNameValid(false);
      isFormValid = false;
      formErrors.push("Uzupełnij imię i nazwisko.");
    }
    if (companyName.trim().length === 0) {
      setIsCompanyNameValid(false);
      isFormValid = false;
      formErrors.push("Uzupełnij miejsce zatrudnienia (nazwę gabinetu).");
    }
    if (!selectedSupplier) {
      setIsSelectedSupplierValid(false);
      isFormValid = false;
      formErrors.push("Wybierz miejsce zakupu pakietu.");
    }
    if (
      supplierName.trim().length === 0 &&
      selectedSupplier === otherSupplier
    ) {
      setIsSupplierNameValid(false);
      isFormValid = false;
      formErrors.push("Uzupełnij miejsce zakupu pakietu.");
    }
    if (invoiceNumber.trim().length === 0) {
      setIsInvoiceNumberValid(false);
      isFormValid = false;
      formErrors.push("Uzupełnij unikalny numer pakietu.");
    }
    if (phone.trim().length === 0) {
      setIsPhoneValid(false);
      isFormValid = false;
      formErrors.push("Uzupełnij numer telefonu.");
    }
    // Validate email using regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailRegex.test(email)) {
      setIsEmailValid(false);
      isFormValid = false;
      formErrors.push("Adres e-mail jest niepoprawny.");
    }
    if (city.trim().length === 0) {
      setIsCityValid(false);
      isFormValid = false;
      formErrors.push("Uzupełnij miejscowość.");
    }
    if (postalCode.length !== 6) {
      setIsPostalCodeValid(false);
      isFormValid = false;
      formErrors.push("Kod pocztowy jest niepoprawny.");
    }
    if (address.trim().length === 0) {
      setIsAddressValid(false);
      isFormValid = false;
      formErrors.push("Uzupełnij ulicę i numer budynku.");
    }
    const requiredAgreementsAreChecked = agreements
      .filter((ag) => ag.isRequired)
      .every((agreement) => agreement.checked);
    if (!requiredAgreementsAreChecked) {
      isFormValid = false;
      formErrors.push("Zaznacz wymagane zgody.");
    }

    if (isFormValid) {
      const formData = {
        name: name,
        dentistOfficeName: companyName,
        packetProvider:
          selectedSupplier === otherSupplier ? supplierName : selectedSupplier,
        packetCode: invoiceNumber,
        mobile: phone,
        email: email,
        city: city,
        postalCode: postalCode,
        street: address,
        recaptcha: recaptchaToken,
        agreements: agreements.map((agreement) => ({
          id: agreement.id,
          isAccepted: agreement.checked,
        })),
      };

      try {
        await axios.post(`${API_URL}/api/packet`, formData);

        setModalHeader("Dziękujemy za zarejestrowanie pakietu!");
        setModalDescription(
          "Twoja Nagroda wkrótce wyruszy w drogę.\nNa podany adres mailowy zostało wysłane potwierdzenie wypełnienia formularzu."
        );
        setModalCloseLabel("Zamknij");
        setIsModalOpen(true);
        setName("");
        setCompanyName("");
        setPostalCode("");
        setCity("");
        setAddress("");
        setEmail("");
        setPhone("");
        setInvoiceNumber("");
        setSelectedSupplier("");
        setSupplierName("");
      } catch (error) {
        const axiosError = error as AxiosError;

        if (axiosError.response) {
          switch (axiosError.response.status) {
            case 422:
              if (
                ((axiosError.response?.data as any).violations as any[]).find(
                  (v) => v.message === "Packet code does not exist"
                )
              ) {
                setModalHeader("Podany przez Ciebie kod nie istnieje.");
                setModalDescription("Prosimy o poprawienie kodu.");
                setModalCloseLabel("Zmień dane");
                setIsModalOpen(true);
              } else if (
                ((axiosError.response?.data as any).violations as any[]).find(
                  (v) => v.message === "Packet code is already registered"
                )
              ) {
                setModalHeader(
                  "Podany przez Ciebie kod został już wykorzystany."
                );
                setModalDescription("Prosimy o poprawienie kodu.");
                setModalCloseLabel("Zmień dane");
                setIsModalOpen(true);
              } else {
                setModalHeader("Ups, coś poszło nie tak...");
                setModalDescription(
                  JSON.stringify(axiosError.response, null, 2)
                );
                setModalCloseLabel("Zamknij");
                setIsModalOpen(true);
              }
              break;

            default:
              setModalHeader("Ups, coś poszło nie tak...");
              setModalDescription(JSON.stringify(axiosError.response, null, 2));
              setModalCloseLabel("Zamknij");
              setIsModalOpen(true);
              break;
          }
        }
      }
    } else {
      setErrors(formErrors);
      setIsErrorModalOpen(true);
    }
    loadReCaptcha();
    setSaving(false);
  };

  return (
    <div id="register-form">
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
      {!loading && (
        <form onSubmit={handleSubmit} style={{ backgroundColor: "#e7eef1" }}>
          <Container>
            <LeftSection style={{ padding: "30px", textAlign: "center" }}>
              <img
                src={NagrodaImg}
                alt=""
                style={{ width: "80%", display: "block", margin: "0 auto" }}
              />
              <strong>
                W Nagrodę oferujemy Zestaw produktów MEDISEPT, a za pierwsze
                1000 rejestracji dodatkowo dołączamy bidon!
              </strong>
            </LeftSection>
            <RightSection>
              <FormContainer isVisible>
                <Headline>REJESTRACJA PAKIETU</Headline>
                <FormColumns>
                  <div>
                    <InputField
                      label="Imię i Nazwisko"
                      type="text"
                      value={name}
                      onChange={setName}
                      isValid={isNameValid}
                    />
                  </div>
                </FormColumns>
                <FormColumns>
                  <div>
                    <InputField
                      label="Miejsce zatrudnienia (Nazwa Gabinetu)"
                      type="text"
                      value={companyName}
                      onChange={setCompanyName}
                      isValid={isCompanyNameValid}
                    />
                  </div>
                </FormColumns>
                <FormColumns>
                  <div>
                    <SupplierList
                      suppliers={suppliers}
                      onSelectSupplier={handleSupplierSelect}
                      isValid={isSelectedSupplierValid}
                    />
                  </div>
                </FormColumns>
                {selectedSupplier === otherSupplier && (
                  <FormColumns>
                    <div>
                      <InputField
                        label="Inne (wpisz jakie)"
                        type="text"
                        value={supplierName}
                        onChange={setSupplierName}
                        isValid={isSupplierNameValid}
                      />
                    </div>
                  </FormColumns>
                )}
                <FormColumns>
                  <div>
                    <InputField
                      label="Unikatowy numer pakietu"
                      type="text"
                      value={invoiceNumber}
                      onChange={setInvoiceNumber}
                      isValid={isInvoiceNumberValid}
                    />
                  </div>
                </FormColumns>
                <Infobox>DANE DO WYSŁKI TWOJEJ NAGRODY DLA KURIERA:</Infobox>
                <FormColumns>
                  <div>
                    <InputField
                      label="numer telefonu"
                      type="tel"
                      value={phone}
                      onChange={setPhone}
                      isValid={isPhoneValid}
                    />
                  </div>
                </FormColumns>
                <FormColumns>
                  <div>
                    <InputField
                      label="adres e-mail"
                      type="email"
                      value={email}
                      onChange={setEmail}
                      isValid={isEmailValid}
                    />
                  </div>
                </FormColumns>
                <FormColumns>
                  <div>
                    <InputField
                      label="miejscowość"
                      type="text"
                      value={city}
                      onChange={setCity}
                      isValid={isCityValid}
                    />
                  </div>
                </FormColumns>
                <FormColumns>
                  <div>
                    <InputField
                      label="kod pocztowy"
                      type="text"
                      value={postalCode}
                      onChange={handlePostalCodeChange}
                      isValid={isPostalCodeValid}
                    />
                  </div>
                </FormColumns>
                <FormColumns>
                  <div>
                    <InputField
                      label="ulica i numer budynku"
                      type="text"
                      value={address}
                      onChange={setAddress}
                      isValid={isAddressValid}
                    />
                  </div>
                </FormColumns>
              </FormContainer>
            </RightSection>
          </Container>
          <div style={{ padding: "0 50px 30px" }}>
            <AgreementCheckboxes
              agreements={agreements}
              onAgreementChange={handleAgreementChange}
            />
            <ButtonsContainer>
              <SubmitButton type="submit" disabled={saving}>
                Wyślij formularz
              </SubmitButton>
            </ButtonsContainer>
          </div>
          {isModalOpen && (
            <Modal
              header={modalHeader}
              description={modalDescription}
              closeLabel={modalCloseLabel}
              onClose={closeModal}
            />
          )}
          {isErrorModalOpen && (
            <ErrorModal
              errors={errors}
              onClose={() => setIsErrorModalOpen(false)}
            />
          )}
        </form>
      )}
    </div>
  );
};

export default RegistrationForm;
