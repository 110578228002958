import React, { useState } from "react";
import styled from "styled-components";

interface SupplierListProps {
  suppliers: string[];
  onSelectSupplier: (supplier: string) => void;
  isValid?: boolean;
}

const Select = styled.select<{ isValid: boolean; isFocused: boolean }>`
  width: 100%;
  padding: 8px 10px;
  font-family: "Saira", sans-serif;
  border-radius: 8px;
  font-size: 16px;
  text-align: center;
  border: 1px solid
    ${({ isValid, isFocused }) =>
      isValid ? (isFocused ? "#E1E1E1" : "#E1E1E1") : "red"};
`;

const FieldContainer = styled.div`
  margin-bottom: 15px;
  position: relative;
`;

const Label = styled.label<{ isFocused: boolean; hasValue: boolean }>`
  color: #4a4a49;
  background-color: #fff;
  padding: 0 5px;
  position: absolute;
  top: 50%; 
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: top 0.3s, font-size 0.3s;
  font-size: 16px;
  text-wrap: nowrap;
  ${({ isFocused, hasValue }) =>
    isFocused || hasValue
      ? `
      top: 2px;
      font-size: 12px;
      font-weight: bold;
    `
      : ""}

  @media screen and (min-width: 768px) {
    max-width: 270px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  @media screen and (min-width: 1024px) {
    max-width: none;
  }
`;

const SupplierList: React.FC<SupplierListProps> = ({
  suppliers,
  onSelectSupplier,
  isValid = true,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  return (
    <FieldContainer>
      <Select
        onChange={(e) => {
          setSelectedValue(e.target.value);
          onSelectSupplier(e.target.value);
        }}
        isValid={isValid}
        isFocused={isFocused}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      >
        <option value="">Miejsce zakupu pakietu</option>
        {suppliers.map((supplier, index) => (
          <option key={index} value={supplier}>
            {supplier}
          </option>
        ))}
      </Select>
      <Label isFocused={isFocused} hasValue={selectedValue !== ""}>
        Miejsce zakupu pakietu
      </Label>
    </FieldContainer>
  );
};

export default SupplierList;
