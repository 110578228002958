import React from "react";
import styled from "styled-components";
import logo1x from "../assets/logo.png";
import logo2x from "../assets/logo@2x.png";
import HeaderImg from "../assets/header.jpg";

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  position: relative;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    // align-items: center;
  }
`;

const LeftColumn = styled.div`
  align-items: center;
  padding: 0 20px;
  margin-bottom: 20px;
  margin-top: 40px;

  @media screen and (min-width: 768px) {
    padding-left: 50px;
    margin-bottom: 0px;
  }

  @media screen and (min-width: 1024px) {
    padding-left: 100px;
    margin-bottom: 0px;
  }
`;

const Logo = styled.img`
  width: 150px;
  height: auto;
  margin-right: 20px;
`;

const RegisterButton = styled.button`
  background-color: #219dd9;
  color: white;
  padding: 4px 15px;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
  font-family: "Saira", sans-serif;

  @media screen and (min-width: 768px) {
    padding: 6px 10px;
    font-size: 14px;
    line-height: 28px;
  }

  @media screen and (min-width: 1024px) {
    padding: 6px 15px;
    font-size: 16px;
    line-height: 38px;
  }

  @media screen and (min-width: 1440px) {
    padding: 6px 20px;
    font-size: 18px;
    line-height: 48px;
  }
`;

const RightColumn = styled.div`
  flex: 1;
  text-align: right;
`;

const HeaderImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const StyledH1 = styled.h1`
  font-family: Saira;
  font-size: 28px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 10px;

  @media screen and (min-width: 768px) {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 20px;
    line-height: 20px;
  }

  @media screen and (min-width: 1024px) {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 34px;
    line-height: 34px;
  }

  @media screen and (min-width: 1440px) {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 48px;
    line-height: 48px;
  }
`;

const StyledH3 = styled.h3`
  font-family: Saira;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 30px;

  @media screen and (min-width: 768px) {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 14px;
  }

  @media screen and (min-width: 1024px) {
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 24px;
  }

  @media screen and (min-width: 1440px) {
    margin-bottom: 40px;
    font-size: 30px;
    line-height: 30px;
  }
`;

const Line = styled.div`
  background-color: #4a4a49;
  position: absolute;
  width: 0px;
  height: 1px;
  top: 65px;
  left: 400px;

  @media screen and (min-width: 768px) {
    width: 200px;
    left: 300px;
  }

  @media screen and (min-width: 1024px) {
    width: 300px;
    left: 400px;
  }

  @media screen and (min-width: 1440px) {
    width: 500px;
    left: 400px;
  }
`;

const Header: React.FC = () => {
  const scrollToForm = () => {
    const element = document.getElementById("register-form");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <HeaderContainer>
      <Line />
      <LeftColumn>
        <Logo
          src={logo1x}
          srcSet={`${logo1x} 1x, ${logo2x} 2x`}
          alt="MediSept"
        />
        <br />
        <StyledH1>
          BEZPIECZNA
          <br />
          ORGANIZACJA PRACY
        </StyledH1>
        <StyledH3>W GABINECIE STOMATOLOGICZNYM</StyledH3>
        <RegisterButton onClick={scrollToForm}>
          Zarejestruj Pakiet i odbierz Nagrodę
        </RegisterButton>
      </LeftColumn>
      <RightColumn>
        <HeaderImage src={HeaderImg} alt="" />
      </RightColumn>
    </HeaderContainer>
  );
};

export default Header;
