import React from "react";

interface ProductCardProps {
  product: any;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  const cardClass = product.isDoubleColumn
    ? "product-card double-column"
    : "product-card";

  return (
    <div
      className={cardClass}
      onClick={() => (product.url ? window.open(product.url, "_blank") : null)}
    >
      <img
        src={product.img}
        alt={product.title}
        style={{ marginBottom: "8px" }}
      />
      <h3>{product.title}</h3>
      <p style={{ fontSize: "13px" }}>{product.description}</p>
    </div>
  );
};

export default ProductCard;
